import React, { useEffect, useState } from "react";
import CharlesMeta from "components/common/meta/CharlesMeta";
import StructuredWebPage from "components/common/meta/StructuredWebPage";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import cx from "classnames";
import Segment, { trackProductViewed, trackSpecialtyChosen } from 'utils/segment'

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Layout } from "components/common";
import TopContainerStart from "../TopContainerStart";

import ReinsuranceColumn from "../ReinsuranceColumn";
import "./styles.sass";

const IndexPage = (props) => {
  const {
    pageContext: category,
    location: { search }
  } = props
  const params = new URLSearchParams(search)
  const showAll = params.get('show') === "all"
  const [otherOpen, setOtherOpen] = useState(showAll);
  const pathologies = category.pathologies.filter(pathology => !pathology.noIndex);
  const handleOtherClick = () => setOtherOpen(!otherOpen);

  useEffect(() => {
    trackProductViewed(category.familySlug)
  }, []);

  return (
    <>
      <Helmet defer={false} >
        <html className="html-class-small-font-page" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout showBars={false} showM6Banner={false}>
        <CharlesMeta
          data={{
            title: category.meta.title,
            description: category.meta.description,
          }}
          segmentCategory={Segment.CATEGORIES.START}
        />
        <StructuredWebPage
          title="Précisez votre pathologie"
          description={category.meta.description}
          showAggregateRating={false}
        />
        <div className="text-center text-md-left start-page w-100 consutler-step-two ">
          <TopContainerStart />
          <Container className="pb-3 pb-sm-5 main-container container-family">
            <Row>
              <Col xs={12} xl={{ span: 8 }} className="left-col">
                <h1 className="section-title main-title text-center ">
                  {" "}
                  Précisez votre pathologie{" "}
                </h1>
                <p className="start-text text-center d-flex start-text-general">
                  Précisez le trouble auquel vous faites face pour que l'on
                  puisse adapter le questionnaire médical et vous mettre en
                  relation avec un médecin spécialisé.
                </p>
                <div className="specialty-button-div">
                  {pathologies.map((spe, index) =>
                    (otherOpen || spe.importance === "top") ? (
                      <Link
                        to={`/consulter-${spe.slug}/`}
                        className=""
                        onClick={() => trackSpecialtyChosen(category.familySlug, spe.specialtySlug)}
                        rel="nofollow"
                      >
                        <button
                          className={cx("specialty-button", category.buttonClassName)}
                          key={index}
                        >
                          {spe.title}
                        </button>
                      </Link>
                    ) : null
                  )}
                  {!otherOpen && <button
                    className="specialty-button show-other-specialties-button"
                    key="autres"
                    onClick={handleOtherClick}
                  >
                    Autres
                  </button>}
                </div>
                <div className="start-general-separator-div mt-5">
                  <div className="start-general-separator d-xl-none flex-column"></div>
                </div>
              </Col>
              <Col className="col-separator " xs={0} xl={1}>
                <div className="start-general-separator-xl "></div>
              </Col>
              <Col
                xs={12}
                xl={{ span: 3 }}
                className="right-col"
              >
                <ReinsuranceColumn />
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    </>
  );
};
export default IndexPage;
